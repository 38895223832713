<template>
  <v-container>
    <ScheduleViewOptions v-if="adminView" />
    <ScheduleSingle
      v-if="targetAuthorData || !$route.query.author"
      :targetAuthorData="targetAuthorData"
      :events="myEvents"
      :eventHeaders="headers"
      :formattedDateRange="formattedDateRange"
      :rsvpTotals="rsvpTotals"
    />
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import ScheduleViewOptions from '@/components/ScheduleViewOptions'
import ScheduleSingle from '@/components/ScheduleSingle'

export default {
  name: 'Schedule',
  components: {
    ScheduleViewOptions,
    ScheduleSingle
  },
  async created () {
    const authorId = this.$route.query.author
    if (authorId) {
      console.log('Loading events for author', authorId)
      try {
        this.targetAuthorData = await this.getTargetAuthor(authorId)
      } catch (err) {
        console.log(err)
      }
    }
    await this.globalFlagsListener()
    console.log('My Events', this.myEvents)
  },
  data: () => ({
    targetAuthorData: null
  }),
  computed: {
    ...mapState(['firestore', 'globalSettings']),
    ...mapGetters([
      'userRole',
      'myEvents',
      'eventHeaders',
      'formattedDateRange'
    ]),
    adminView () {
      return this.userRole === 'admin'
    },
    headers () {
      return this.eventHeaders(this.adminView, this.targetAuthorData)
    },
    rsvpTotals () {
      const totals = {}
      if (!this.myEvents) return totals
      this.myEvents.forEach((event) => {
        if (event.rsvps) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(event.rsvps)) {
            if (value === 'yes') {
              if (totals[event.id]) {
                totals[event.id]++
              } else {
                totals[event.id] = 1
              }
            }
          }
        }
        if (event.plusOneRsvps) {
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(event.plusOneRsvps)) {
            if (value === 'yes') {
              if (totals[event.id]) {
                totals[event.id]++
              } else {
                totals[event.id] = 1
              }
            }
          }
        }
      })
      console.log('RSVP Totals:', totals)
      return totals
    }
  },
  methods: {
    ...mapActions([
      'getTargetAuthor',
      'globalFlagsListener'
    ])
  }
}
</script>
