<template>
  <div class="schedule-wrapper">
    <v-img
      src="@/assets/images/BF_Logo_Full_CROPPED.svg"
      contain
      max-height="140"
      class="nobf-logo mb-5 mt-2"
      eager
    />
    <h2 v-if="targetAuthorData && targetAuthorData.fullName">
      Speaker Name: {{ targetAuthorData.fullName }}
    </h2>
    <div class="rsvp-messages">
      <p v-if="requiresRsvp" class="error--text">
        <v-icon class="error--text">mdi-alert</v-icon>
        <strong>You have events which require an RSVP.</strong>
      </p>
      <p v-if="!requiresRsvp && alreadyRsvped">
        <strong>We appreciate your RSVP. For any changes to your planned schedule, please contact us.</strong>
      </p>
    </div>
    <table class="schedule-table">
      <tr>
        <td>Event Date:</td>
        <td v-if="formattedDateRange">
          {{ formattedDateRange }}
        </td>
      </tr>
      <tr>
        <td>Location:</td>
        <td>New Orleans, LA</td>
      </tr>
      <tr>
        <td>Organization:</td>
        <td>New Orleans Book Festival</td>
      </tr>
      <tr v-if="hasBooksSold">
        <td>Books Sold:</td>
        <td>
          <div
            v-for="(book, index) in targetAuthorData.booksSold"
            :key="`book-${index}`"
          >
            <em>{{ book }}</em>
          </div>
        </td>
      </tr>
      <tr>
        <td>Attire:</td>
        <td>Business Casual</td>
      </tr>
      <tr v-if="targetAuthorData && targetAuthorData.onsiteContacts && targetAuthorData.onsiteContacts.length">
        <td>Onsite Contact(s):</td>
        <td>
          <div
            v-for="(contact, index) in targetAuthorData.onsiteContacts"
            :key="`contact-${index}`"
          >
            {{ contact.name || '' }}<!--
            --><template v-if="contact.phone">, <a :href="`tel:${contact.phone}`">{{ contact.phone }}</a></template><!--
            --><template v-if="contact.email">, <a v-if="contact.email" :href="`mailto:${contact.email}`">{{ contact.email }}</a></template>
          </div>
        </td>
      </tr>
    </table>
    <div v-if="targetAuthorData" class="top-notes">
      <strong><p>If you cannot reach your author ambassador, please contact the author hotline at
        <a href="tel:+15043142222" style="text-decoration:none;">(504) 314-2222</a>
      </p></strong>
      <strong>
        <p>
          Please note, any disruption during sessions caused by attendees will be handled by security and event staff. Please do not address any issues from the stage.
        </p>
      </strong>
    </div>

    <!-- Events Table -->
    <v-data-table
      v-if="events && events.length"
      :headers="eventHeaders"
      :items="events"
      :sort-by="'start'"
      :search="search"
      group-by="date"
      :show-group-by="true"
    >
      <template v-slot:top>
        <v-text-field
          v-if="adminView"
          v-model="search"
          label="Search"
          append-icon="mdi-magnify"
        />
      </template>
      <template v-slot:[`group.header`]="{ items }">
        {{ formatIsoDate(items[0].date) }}
      </template>
      <template v-slot:[`item.time`]="{ item }">
        <template v-if="item.start && item.end && !item.dateTimeTbd">
          {{ militaryToAmPm(item.start) }} - {{ militaryToAmPm(item.end) }}
        </template>
        <template v-else>
          TBD
        </template>
      </template>
      <template v-slot:[`item.rsvpData.requiresRsvp`]="{ item }">
        <div v-if="item.rsvpData.requiresRsvp" class="yes-no">
          <!-- If user is an admin, they can still modify these values. -->
          <v-checkbox
            :disabled="!adminView && item.rsvpData.rsvpConfirmed"
            v-model="item.rsvpData.rsvpYes"
            label="Yes"
            hide-details
            @change="confirmRsvp(item, null, 'yes')"
          ></v-checkbox>
          <v-checkbox
            :disabled="!adminView && item.rsvpData.rsvpConfirmed"
            v-model="item.rsvpData.rsvpNo"
            label="No"
            hide-details
            @change="confirmRsvp(item, null, 'no')"
          ></v-checkbox>
        </div>
      </template>
      <template v-slot:[`item.rsvpData.requiresPlusOneRsvp`]="{ item }">
        <div v-if="item.rsvpData.requiresPlusOneRsvp" class="yes-no">
          <v-checkbox
            :disabled="!adminView && item.rsvpData.plusOneRsvpConfirmed"
            v-model="item.rsvpData.plusOneRsvpYes"
            label="Yes"
            hide-details
            @change="confirmRsvp(item, 'plus-one', 'yes')"
          ></v-checkbox>
          <v-checkbox
            :disabled="!adminView && item.rsvpData.plusOneRsvpConfirmed"
            v-model="item.rsvpData.plusOneRsvpNo"
            label="No"
            hide-details
            @change="confirmRsvp(item, 'plus-one', 'no')"
          ></v-checkbox>
        </div>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        {{ formatIsoDate(item.date) }}
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <span class="formatted">{{ item.description }}</span><br>
        <span v-if="adminView && rsvpTotals && rsvpTotals[item.id]">
          <strong>Total RSVPed: {{ rsvpTotals[item.id] }}</strong>
        </span>
      </template>

      <template v-slot:[`item.location`]="{item}">
        <pre v-if="item.location">{{ locationById(item.location) }}</pre>
      </template>

      <template v-slot:[`item.tags`]="{item}">
        <v-chip v-for="tag in item.tags" :key="tag.id">
          {{ tag.length >= 20 && authorById(tag) ? authorById(tag).fullName : tag.toUpperCase() }}
        </v-chip>
      </template>

      <template v-slot:[`item.rsvpTags`]="{item}">
        <v-chip v-for="tag in item.rsvpTags" :key="tag.id">
          {{ tag.length >= 20 && authorById(tag) ? authorById(tag).fullName : tag.toUpperCase() }}
        </v-chip>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="editEvent(item)" class="pa-1">
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="deleteEvent(item)"
          color="error"
          class="pa-1"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-progress-circular v-else-if="!eventsInitialized" indeterminate size="80" color="primary"></v-progress-circular>
    <p v-else>It looks like there aren't any related events for this author.</p>

    <!-- Flight(s) -->
    <section v-if="targetAuthorData && targetAuthorData.airlineInfo">
      <hr>
      <h2>Airline Information</h2>
      <pre v-html="targetAuthorData.airlineInfo"></pre>
    </section>

    <!-- Hotel -->
    <section v-if="targetAuthorHotelData" class="hotel">
      <hr>
      <h2>Hotel Information</h2>
      <HotelCard :hotel="targetAuthorHotelData" />
    </section>

    <!-- Additional Notes -->
    <section v-if="targetAuthorData && targetAuthorData.notes">
      <hr>
      <h2>Additional Notes</h2>
      <p>{{ targetAuthorData.notes }}</p>
    </section>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
import { httpsCallable } from 'firebase/functions'
import { getDoc, doc } from 'firebase/firestore'
import HotelCard from '@/components/HotelCard'

export default {
  name: 'ScheduleSingle',
  components: {
    HotelCard
  },
  props: {
    targetAuthorData: {
      type: Object,
      default: () => {}
    },
    events: {
      type: Array,
      default: () => []
    },
    eventHeaders: {
      type: Array,
      default: () => []
    },
    formattedDateRange: {
      type: String,
      default: ''
    },
    rsvpTotals: {
      type: Object,
      default: () => {}
    }
  },
  data: () => ({
    search: '',
    targetAuthorHotelData: null
  }),
  computed: {
    ...mapState([
      'firestore',
      'fbFunctions'
    ]),
    ...mapGetters([
      'userRole',
      'locationById',
      'requiresRsvp',
      'alreadyRsvped',
      'authorById',
      'eventsInitialized'
    ]),
    adminView () {
      return this.userRole === 'admin'
    },
    hasBooksSold () {
      return (
        this.targetAuthorData &&
        this.targetAuthorData.booksSold &&
        this.targetAuthorData.booksSold.length > 0 &&
        this.targetAuthorData.booksSold[0] !== ''
      )
    }
  },
  methods: {
    ...mapActions([
      'firestoreDeleteEvent'
    ]),
    ...mapMutations([
      'setGlobalNotification'
    ]),
    editEvent (event) {
      this.$root.$emit('showEventEditor', event)
    },
    deleteEvent (event) {
      if (confirm('Are you sure you want to delete this event?')) {
        this.firestoreDeleteEvent(event.id)
      }
    },
    async confirmRsvp (event, type, inputStatus) {
      let status = inputStatus
      if (type === 'plus-one') {
        // Clear the other input if it's the opposite of the one being set.
        if (inputStatus === 'yes') {
          event.rsvpData.plusOneRsvpNo = false
        } else if (inputStatus === 'no') {
          event.rsvpData.plusOneRsvpYes = false
        }
        // If neither are set, set status to null.
        if (!event.rsvpData.plusOneRsvpYes && !event.rsvpData.plusOneRsvpNo) {
          status = null
        }
      } else {
        if (inputStatus === 'yes') {
          event.rsvpData.rsvpNo = false
        } else if (inputStatus === 'no') {
          event.rsvpData.rsvpYes = false
        }
        if (!event.rsvpData.rsvpYes && !event.rsvpData.rsvpNo) {
          status = null
        }
      }
      try {
        const res = await httpsCallable(this.fbFunctions, 'rsvpToEvent')({
          eventId: event.id,
          authorId: this.targetAuthorData.id,
          status,
          type
        })
        console.log(res)
        this.setGlobalNotification({
          val: `Thank you for confirming your RSVP for ${event.description}.`
        })
      } catch (err) {
        console.log(err)
        this.setGlobalNotification({
          val: `Error confirming your RSVP for ${event.description}.`,
          type: 'error'
        })
      }
    }
  },
  async created () {
    if (this.targetAuthorData?.hotel) {
      try {
        const hotel = await getDoc(doc(this.firestore, 'hotels', this.targetAuthorData.hotel))
        this.targetAuthorHotelData = hotel.data()
      } catch (err) {
        console.log(err)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-wrapper {
  .v-progress-circular {
    display: block;
    margin: 0 auto;
  }
}
.schedule-table {
  td {
    padding: 5px 0;
  }
  td:nth-child(1) {
    padding-right: 20px;
    font-weight: 700;
    vertical-align: top;
  }

  @media print {
    td {
      padding-top: 0;
      padding-bottom: 0;
      font-size: 12px;
    }
  }
}

.yes-no {
  display: flex;
  gap: 12px;

  .v-input {
    margin-top: 0;
    padding-top: 0;
  }
  ::v-deep .v-input--selection-controls__input {
    margin-right: 4px;
  }

  @media print {
    display: none;
  }
}

section {
  padding: 20px 0 0;
  h2 {
    margin: 10px 0;
  }
}

@media print {
  .rsvp-messages {
    display: none;
  }
  .nobf-logo {
    height: 100px;
  }
}
</style>
